/* this file will be extracted to main dist folder and is imported in index.html */
/* This file is for setting global styles  */
/***************************************
    ANGULAR 2 MATERIAL THEME STYLES
****************************************/
/**
 Important: this block may not work and throw errors
 if dependencies in package.json are incorrect/broken
 Approved working versions/dependencies:
 "dependencies":
 "@angular/core": "2.4.8", "@angular/material": "^2.0.0-beta.2",
 "@angular/router": "3.4.8", "angular-cli": "^1.0.0-beta.28.3",
 "hammerjs": "^2.0.8", "systemjs": "0.19.41", "zone.js": "0.7.7"
 "devDependencies":
 "@angular/compiler-cli": "2.4.8", "css-loader": "^0.26.1",
 "raw-loader": "^0.5.1", "sass-loader": "^6.0.2",
**/
body {
  font-family: 'AvenirLTStd-Book', sans-serif !important; }

.avenir {
  font-family: 'AvenirLTStd-Book', sans-serif !important; }

.roboto {
  font-family: 'Roboto', "Helvetica Neue", sans-serif; }

.flex {
  display: flex !important; }

.flex-column {
  flex-direction: column; }

.flex1 {
  flex: 1; }

.align-center {
  align-items: center; }

.align-end {
  align-items: flex-end; }

.block {
  display: block; }

.i-block {
  display: inline-block; }

.justify-center {
  justify-content: center; }

.justify-end {
  justify-content: flex-end; }

.space-between {
  justify-content: space-between; }

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.absolute {
  position: absolute; }

.p-0 {
  padding: 0; }

.m-0 {
  margin: 0; }

.m-10 {
  margin: 10px; }

.ml-10 {
  margin-left: 10px; }

.ml-20 {
  margin-left: 20px; }

.mb-5 {
  margin-bottom: 5px; }

.mb-10 {
  margin-bottom: 10px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-40 {
  margin-bottom: 40px; }

.uppercase {
  text-transform: uppercase; }

.underline {
  text-decoration: underline; }

.pointer {
  cursor: pointer; }

.fw-400 {
  font-weight: 400; }

.fw-700 {
  font-weight: 700; }

.font-8 {
  font-size: 8px; }

.font-9 {
  font-size: 9px; }

.font-10 {
  font-size: 10px; }

.font-12 {
  font-size: 12px; }

.font-14 {
  font-size: 14px; }

.font-16 {
  font-size: 16px; }

.font-18 {
  font-size: 18px; }

/***************************************
            ROOT STYLES
****************************************/
@font-face {
  font-family: 'AvenirLTStd-Book';
  src: url(d54e2370bf3c06ddc916f70d74280ac4.eot);
  src: url(d54e2370bf3c06ddc916f70d74280ac4.eot?#iefix) format("embedded-opentype"), url(7c6098c31f8e9e76689fd92dbbe15678.woff) format("woff"), url(58db7f7e05df637ed923678dc673d65e.ttf) format("truetype"), url(ecb0c2ae369ba2a89d9a1ec2a1b3187b.svg#AvenirLTStd-Book) format("svg"); }

* {
  box-sizing: border-box; }

body, html, main, .main, .app, app {
  margin: 0;
  height: 100%;
  background-color: #efefef;
  min-width: 320px; }

body {
  font-family: 'AvenirLTStd-Book'; }

.layout-container {
  height: 100%; }

.main {
  overflow-y: hidden; }

app-footer {
  display: block;
  bottom: 20px;
  position: absolute;
  width: 100%;
  left: 0; }

.footer {
  width: 100%;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  height: 80px; }
  .footer .nav a, .footer .nav span {
    color: #00bbbc;
    text-decoration: none;
    transition: all 0.3s;
    cursor: pointer; }

/***************************************
            COMMON STYLES
****************************************/
.font-bold-500 {
  font-weight: 500; }

.font-bold-600 {
  font-weight: 600; }

.center {
  text-align: center; }

.color-violet {
  color: #3325ae; }

.color-white {
  color: #fff; }

.color-grey-light {
  color: #EEEEEE; }

.color-green {
  color: #B0D668; }

.content-justified {
  justify-content: space-between; }

.default-header {
  display: block;
  float: left;
  color: #000;
  width: 100%; }

.default-header, .side-bar {
  display: none; }

.dynamic-content-divider {
  display: block;
  margin: 0;
  padding: 0;
  line-height: 64px;
  height: 64px;
  width: 100%; }

.disabled {
  cursor: not-allowed;
  pointer-events: none !important;
  opacity: .3; }

.button-disabled {
  cursor: not-allowed;
  opacity: .7; }

.no-border-radius {
  border-radius: 0 !important; }

.clear-container {
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%; }

.list-style-none {
  text-decoration: none;
  list-style: none; }

.text-indent {
  text-indent: -23px; }

.text-indent-first {
  text-indent: 23px;
  justify-content: left; }

.text-upper {
  text-transform: uppercase; }

.env-label {
  font-size: 12px;
  font-weight: 600;
  color: #a7b1c2;
  position: absolute;
  left: 18px;
  bottom: 14px;
  text-transform: uppercase; }

.inline-block {
  display: inline-block; }

.input-initial {
  display: inline-block;
  font-size: 14px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #CECECE;
  line-height: 25px;
  outline: none;
  margin-bottom: 10px;
  position: relative;
  text-align: left;
  min-width: 120px; }

.mdMenu {
  padding: initial; }

.middle {
  vertical-align: middle !important; }

.page-header {
  font-size: 22px;
  font-weight: 600;
  padding-left: 12px; }

.pointer {
  cursor: pointer; }

.pullLeft {
  float: left !important; }

.pullRight {
  float: right !important; }

.pull-top {
  vertical-align: top; }

.placeholder-italic::-webkit-input-placeholder {
  font-style: italic; }

.placeholder-italic:-moz-placeholder {
  font-style: italic; }

.placeholder-italic::-moz-placeholder {
  font-style: italic; }

.placeholder-italic:-ms-input-placeholder {
  font-style: italic; }

.full-width-block {
  min-width: 100%;
  width: 100%;
  display: inline-block; }

.overflow-x-auto {
  overflow-x: auto; }

.request-progress-spinner {
  position: absolute;
  right: 4px;
  top: 1px;
  height: 44px !important;
  width: 44px !important; }

.crowdrise-loading-spinner {
  position: absolute;
  right: -48px;
  top: 0;
  height: 36px !important;
  width: 36px !important;
  z-index: 97; }

.text-center {
  text-align: center; }

.no-border {
  border: none !important; }

.font-12 {
  font-size: 12px; }

.font-14 {
  font-size: 14px; }

.font-16 {
  font-size: 16px; }

.font-18 {
  font-size: 18px; }

.font-20 {
  font-size: 20px; }

.page-content .form-edit-new-content.additional-content-block .beam-image-card .cards-image-container {
  margin: 0 auto;
  text-align: center;
  width: 180px !important; }

.mat-icon-social {
  margin-left: 4px; }

.action-button-square, .action-button-square.mat-button, .action-button-square.mat-button {
  min-width: 36px;
  padding: 0;
  height: 100%;
  max-height: 48px;
  max-width: 48px;
  width: 48px !important; }

.action-button-default {
  height: 100%;
  max-height: 46px;
  padding: 0; }

.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap; }

loading-spinner {
  z-index: 75; }

.shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08); }

.shadow-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.shadow-1:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  z-index: 96; }

.shadow-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }

.shadow-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }

.shadow-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

.shadow-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }

.shadow-light {
  box-shadow: 0 2px 25px rgba(11, 63, 117, 0.15); }

[hidden] {
  display: none !important; }

.background-light-grey {
  background-color: #fafafa; }

.background-white {
  background-color: #fff !important; }

.beam-image-card {
  background-color: #fff;
  margin-bottom: 12px;
  text-align: center; }
  .beam-image-card .beam-asset-title, .beam-image-card .user-beams-toggle {
    color: #000;
    display: inline-block;
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 24px; }
  .beam-image-card .user-beams-toggle-container {
    text-align: right;
    width: calc(100% - 110px); }
  .beam-image-card .user-beams-toggle {
    margin-top: 0; }
  .beam-image-card .beam-asset-img {
    display: inline-block;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    height: 180px !important;
    width: 180px !important;
    vertical-align: middle; }
  .beam-image-card .card-image-container {
    display: block;
    text-align: center;
    width: 100%; }
  .beam-image-card .cards-image-container {
    display: inline-block;
    margin: 2px;
    position: relative;
    text-align: center;
    height: calc(33% - 6px) !important;
    width: calc(33% - 6px) !important; }
    .beam-image-card .cards-image-container .beam-asset-img {
      margin: 0 !important;
      vertical-align: bottom;
      height: 100% !important;
      width: 100% !important; }
  .beam-image-card .user-picture-container {
    z-index: 97; }
    .beam-image-card .user-picture-container .beam-asset-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto !important;
      width: 180px !important;
      z-index: 95; }

.beam-image-card.mobile-content.mat-card, .beam-image-card.mobile-content.mat-card {
  display: inline-block;
  width: 100%; }

.beam-tags-container {
  margin: 0 12px 18px;
  position: relative;
  width: 100%; }
  .beam-tags-container .tag-add-button {
    position: absolute;
    right: 12px;
    top: -56px; }
  .beam-tags-container .beam-tag-chip {
    margin: 2px;
    min-height: 32px;
    max-width: 220px;
    padding-right: 32px !important;
    position: relative;
    outline: none !important; }

.icon-right-absolute {
  cursor: pointer;
  position: absolute;
  right: 24px; }

.chip-action-button {
  color: darkslategrey;
  font-size: 22px;
  position: absolute;
  right: 5px;
  top: 5px; }

.connect-crowdrise-button {
  position: relative;
  min-width: 202px !important; }

.crowdrise-button-image {
  display: inline-block;
  position: relative;
  margin: 0 4px 3px;
  padding: 0;
  line-height: 32px;
  height: auto;
  width: 100px;
  vertical-align: middle !important; }

.crowdrise-connected-banner {
  display: inline-block;
  font-size: 14px;
  text-align: left;
  position: relative;
  float: left;
  margin: 6px 0;
  padding: 0;
  line-height: 38px; }

.user-profile-img-small-container {
  border-radius: 50%;
  display: inline-block;
  float: left;
  padding: 0;
  position: relative;
  margin: 7px 15px 0 -4px;
  overflow-y: hidden;
  text-align: center;
  height: 32px;
  width: 32px; }

.user-profile-img-small {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 32px; }

.connected-fields-icon {
  position: absolute;
  top: -24px;
  left: calc(50% - 12px); }

.geo-autocomplete-address {
  margin-top: 0 !important;
  margin-bottom: 12px !important; }
  .geo-autocomplete-address .line .line-recent {
    display: block !important; }
  .geo-autocomplete-address li, .geo-autocomplete-address li a {
    text-align: left !important; }

.custom-autocomplete__input .search-icon {
  border: none !important; }
  .custom-autocomplete__input .search-icon .search-default-icon {
    height: 18px !important;
    width: 18px !important; }

.custom-autocomplete__input input {
  border: none !important;
  border-bottom: 1px solid lightgray !important;
  outline: none;
  padding-bottom: 2px !important;
  padding-left: 0 !important;
  height: 37px !important; }

.icon-right-top-absolute, .icon-left-top-absolute {
  cursor: pointer;
  position: absolute;
  top: -12px;
  line-height: 24px !important; }

.icon-left-top-absolute {
  left: 0; }

.icon-right-top-absolute {
  right: 0;
  z-index: 106; }

.relative {
  position: relative; }

.relative-inline-block {
  display: inline-block;
  position: relative; }

.grey {
  color: #505050; }

.slideshow-asset-td {
  min-width: 192px !important;
  text-align: center; }

.with-id-header {
  margin-top: 22px !important; }

.beam-id-header {
  position: absolute;
  top: -16px; }

.hide-tab-header mat-tab-header, .hide-tab-header mat-tab-header {
  display: none !important; }

.mat-menu-content, .mat-menu-content {
  background: #f4f8f9 !important; }

.line-with-angle-overlay {
  background-color: #585b5d;
  border-right: 2px solid #E4E4E4;
  outline: none;
  position: absolute;
  left: calc(50% - 4px);
  top: calc(50% - 11px);
  transform: rotate(-45deg);
  height: 24px;
  width: 4px; }

.label-top {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 12px 12px; }

.note-text {
  display: inline-block;
  position: relative;
  margin: 12px; }

.mat-padding h3 {
  font-size: 16px;
  padding-left: 12px; }

.rotateBwd90 {
  transform: rotate(-90deg); }

.rotateFwd90 {
  transform: rotate(90deg); }

.rotateFwd180 {
  transform: rotate(180deg); }

.cursor-pointer {
  cursor: pointer; }

.darkblue {
  color: darkblue !important; }

.green {
  color: green !important; }

.red {
  color: #ff465d !important; }

.min-height-200 {
  min-height: 200px; }

.height-100vh-150 {
  min-height: calc(100vh - 150px) !important; }

.width-10 {
  width: 10% !important; }

.width-20 {
  width: 20% !important; }

.width-33 {
  width: 33% !important; }

.width-33-padding {
  margin: 0 1%;
  width: 30% !important; }

.width-40 {
  width: 40% !important; }

.width-50 {
  width: 50% !important; }

.width-50-padding {
  padding: 0 20px !important;
  width: calc(50% - 20px) !important; }

.width-67-padding {
  padding-left: 30px;
  width: 67%; }

.width-100 {
  width: 100% !important; }

.width-100-label {
  width: calc(100% - 100px) !important; }
  .width-100-label .table-container-scroll {
    max-height: none !important; }

.width-max-100px {
  max-width: 100px !important; }

.width-300px {
  width: 300px; }
  .width-300px input {
    width: 300px; }

.width-60px {
  min-width: 60px !important;
  width: 60px !important; }

.width-100px {
  min-width: 100px !important;
  width: 100px !important; }

.width-fit-content {
  min-width: fit-content !important;
  width: fit-content !important; }

.height-100 {
  height: 100%; }

.no-padding {
  padding: 0 !important; }

.rounded {
  border-radius: 50%;
  overflow: hidden; }
  .rounded img {
    border-radius: 50%;
    overflow: hidden; }

.hidden {
  height: 0 !important;
  overflow: hidden !important;
  opacity: 0 !important; }

.removed {
  display: none !important; }

.beam-img-l {
  height: 100px !important;
  width: 100px !important; }

.beam-size-100 {
  height: 100px !important;
  width: 100px !important; }
  .beam-size-100 img {
    height: 101px !important;
    width: 101px !important; }

.label-left-spaced {
  margin-right: 5px; }

.dragula-bag-bordered {
  border: 1px solid #5eb000; }

.border-orange {
  border: 2px solid #5eb000 !important; }

.border-green {
  border: 3px solid #B0D668 !important; }

.border-color-orange {
  border-color: #5eb000 !important; }

.color-orange {
  color: #5eb000; }
  .color-orange .mat-button-wrapper {
    color: #5eb000 !important; }

button {
  outline: none; }

.cdk-overlay-container .cdk-global-overlay-wrapper div.cdk-overlay-pane {
  margin-bottom: calc(50vh - 24px) !important; }
  .cdk-overlay-container .cdk-global-overlay-wrapper div.cdk-overlay-pane snack-bar-container.mat-snack-bar-container.mat-snack-bar-center {
    transform: translateY(0%) scale(1.3, 1.3) !important; }

/***************************************
         TABLE GENERAL STYLES
****************************************/
.snackbar-custom {
  background-color: grey; }

.mat-table-container {
  max-height: calc(100vh - 270px); }

.mat-table-container, .mat-table-container {
  overflow-x: scroll !important; }

.mat-table-container .td-data-table tbody tr .td-data-table-cell:nth-of-type(1), .mat-table-container .td-data-table thead th.td-data-table-column:nth-of-type(1),
.mat-table-container .td-data-table tbody tr .td-data-table-cell:nth-of-type(2), .mat-table-container .td-data-table thead th.td-data-table-column:nth-of-type(2), .mat-table-container .td-data-table tbody tr .td-data-table-cell:nth-of-type(1), .mat-table-container .td-data-table thead th.td-data-table-column:nth-of-type(1),
.mat-table-container .td-data-table tbody tr .td-data-table-cell:nth-of-type(2), .mat-table-container .td-data-table thead th.td-data-table-column:nth-of-type(2) {
  padding: 0 12px !important;
  width: 24px !important; }

table {
  text-align: left; }
  table thead .icon-th-small {
    display: inline-block;
    position: relative;
    margin: 0px 2px;
    transition: all .2s ease;
    height: 18px;
    width: 18px;
    opacity: .3;
    font-size: 18px;
    vertical-align: bottom; }
  table thead tr th:hover .icon-th-small {
    opacity: 1; }
  table thead tr th .icon-th-small.active {
    opacity: 1; }
  table tbody tr {
    outline: none !important; }
    table tbody tr td .td-data-table-cell, table tbody tr th .td-data-table-cell {
      text-overflow: ellipsis; }

td-data-table {
  min-width: 3500px; }

.data-table-actions-container {
  background-color: #fff;
  display: block;
  padding: 0;
  position: relative;
  text-align: left;
  height: 48px;
  width: 100%;
  max-height: 70px;
  min-height: 48px;
  -moz-transition: height .2s ease !important;
  -webkit-transition: height .2s ease !important;
  -o-transition: height .2s ease !important;
  transition: height .2s ease !important;
  vertical-align: top; }
  .data-table-actions-container mat-slide-toggle {
    display: inline-block;
    margin: 4px 10px 4px 4px;
    position: relative; }
  .data-table-actions-container .data-table-checkbox-all {
    float: left;
    margin: 0;
    padding: 14px 12px;
    text-align: center;
    height: 48px;
    width: 58px; }
  .data-table-actions-container .user-table-actions {
    padding: 0 12px;
    height: 48px;
    width: 48px;
    min-width: 48px;
    vertical-align: top; }
  .data-table-actions-container .table-action-button {
    box-sizing: border-box;
    padding-left: 8px;
    text-align: center;
    margin: 3px 20px 3px 0;
    height: 42px;
    width: 78px; }
  .data-table-actions-container .back-button {
    padding-left: 6px; }
  .data-table-actions-container .save-button {
    height: 42px; }

.beam-table-img, .beam-table-img-l {
  border-radius: 50%;
  margin: 0 auto;
  height: 46px;
  width: 46px;
  vertical-align: middle; }

.beam-table-img-l {
  height: 62px;
  width: 62px; }

.beam-preview-container {
  margin: 10px; }

.beam-table-img-container {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin: 0 auto;
  overflow-y: hidden;
  height: 46px;
  width: 46px;
  vertical-align: middle;
  z-index: 97; }
  .beam-table-img-container .beam-table-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: 100%;
    z-index: 95; }

.table-img-container {
  display: inline-block;
  position: relative;
  margin: 1px auto;
  overflow-y: hidden;
  height: 46px;
  vertical-align: middle;
  z-index: 97; }
  .table-img-container .beam-table-img {
    border-radius: 0 !important;
    height: 46px;
    width: auto;
    z-index: 95;
    vertical-align: bottom; }

.table-asset-native-width {
  border-radius: 0 !important;
  height: 46px;
  width: auto;
  z-index: 95;
  vertical-align: bottom; }

.table-datetime-cell {
  min-width: 105px;
  width: 130px; }

.table-td-common-cell {
  word-break: normal; }

.table-truncated-cell {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  word-break: break-all !important;
  white-space: nowrap !important;
  min-width: 130px !important;
  width: fit-content;
  max-width: 220px !important; }

.table-fit-content-cell {
  width: auto;
  min-width: -webkit-fill-available;
  min-width: available;
  max-width: 220px !important;
  display: flex;
  align-items: center; }

.table-label-title {
  display: block;
  height: 48px;
  line-height: 48px;
  min-width: 100px;
  text-align: center; }

.table-lable-icon {
  display: none !important;
  line-height: 48px;
  vertical-align: middle; }

.mat-tab-label, .mat-tab-label {
  min-width: 48px !important;
  text-align: center; }
  .mat-tab-label .mat-icon, .mat-tab-label .mat-icon, .mat-tab-label .mat-icon, .mat-tab-label .mat-icon {
    padding: 0 !important;
    margin: 0 auto !important; }

.mat-icon.additional-top-icon, .mat-icon.additional-top-icon {
  color: #000;
  font-size: 18px;
  line-height: 18px;
  position: absolute;
  right: calc(50% - 18px);
  top: 6px;
  height: 12px !important;
  width: 12px !important;
  z-index: 2; }

.table-row-status-icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle; }

/***************************************
         DATA EDIT FORM STYLES
****************************************/
.data-table-container {
  padding: 1px;
  border: 1px solid #D8D8D8;
  height: auto;
  width: 100%; }
  .data-table-container i {
    color: #2F4050;
    float: none;
    position: relative;
    vertical-align: middle; }
  .data-table-container .data-table-checkbox.mat-checkbox .mat-checkbox-inner-container {
    margin: 0 8px !important; }
  .data-table-container .mat-select-trigger, .data-table-container .mat-select-value-text {
    color: #000 !important;
    font-size: 13px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap; }
  .data-table-container .table-pagination-bar {
    display: inline-block;
    font-size: 13px !important;
    float: right;
    line-height: 22px !important;
    text-align: right;
    position: relative;
    width: 70%;
    min-width: 320px; }
    .data-table-container .table-pagination-bar mat-select {
      height: 32px;
      vertical-align: middle;
      max-width: 36px;
      min-width: 36px;
      width: 36px; }
      .data-table-container .table-pagination-bar mat-select .mat-select-trigger {
        max-width: 36px;
        min-width: 36px;
        width: 36px; }
    .data-table-container .table-pagination-bar mat-select, .data-table-container .table-pagination-bar .td-paging-bar-navigation, .data-table-container .table-pagination-bar .td-paging-bar-ranges {
      margin: 0 !important; }
    .data-table-container .table-pagination-bar .td-paging-bar div:nth-of-type(1), .data-table-container .table-pagination-bar .td-paging-bar div.mat-select-trigger {
      display: inline-block !important; }
    .data-table-container .table-pagination-bar .td-paging-bar div.mat-select-trigger {
      display: flex !important; }
  .data-table-container .td-paging-bar-ranges {
    display: inline-block !important;
    margin: 0 !important; }
  .data-table-container .td-paging-bar-per-page {
    margin-left: 0 !important; }
  .data-table-container .td-paging-bar-navigation {
    display: inline-block;
    position: relative;
    width: 175px; }
  .data-table-container .users-data-table {
    z-index: 95; }
  .data-table-container tbody tr:nth-child(odd) {
    background-color: #fff; }
  .data-table-container thead tr {
    border-top: 1px solid lightgrey;
    color: #000; }
    .data-table-container thead tr th {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      min-height: 41px; }
      .data-table-container thead tr th .mat-icon {
        vertical-align: top; }
  .data-table-container thead tr, .data-table-container tbody tr {
    border-color: lightgrey; }
  .data-table-container tbody tr {
    color: #3A3A3A; }

.table-default {
  border-collapse: collapse;
  overflow-x: scroll;
  width: 100%; }
  .table-default tbody tr, .table-default thead tr {
    border-bottom: 1px solid lightgrey;
    height: 32px; }
    .table-default tbody tr td, .table-default tbody tr th, .table-default thead tr td, .table-default thead tr th {
      border-right: 1px solid lightgrey;
      padding-left: 6px;
      padding-right: 6px;
      min-width: 30px; }
    .table-default tbody tr th.table-module-th-check.ng-star-inserted .table-fit-content-cell, .table-default thead tr th.table-module-th-check.ng-star-inserted .table-fit-content-cell {
      text-align: center;
      max-width: 70px !important; }
    .table-default tbody tr th.table-module-th-delete.ng-star-inserted .table-fit-content-cell, .table-default thead tr th.table-module-th-delete.ng-star-inserted .table-fit-content-cell {
      text-align: center;
      max-width: 100px !important; }
    .table-default tbody tr th.table-module-th-select.ng-star-inserted,
    .table-default tbody tr td.table-module-td-select.ng-star-inserted, .table-default thead tr th.table-module-th-select.ng-star-inserted,
    .table-default thead tr td.table-module-td-select.ng-star-inserted {
      width: 60px !important; }
      .table-default tbody tr th.table-module-th-select.ng-star-inserted .table-fit-content-cell,
      .table-default tbody tr th.table-module-th-select.ng-star-inserted .table-truncated-cell,
      .table-default tbody tr td.table-module-td-select.ng-star-inserted .table-fit-content-cell,
      .table-default tbody tr td.table-module-td-select.ng-star-inserted .table-truncated-cell, .table-default thead tr th.table-module-th-select.ng-star-inserted .table-fit-content-cell,
      .table-default thead tr th.table-module-th-select.ng-star-inserted .table-truncated-cell,
      .table-default thead tr td.table-module-td-select.ng-star-inserted .table-fit-content-cell,
      .table-default thead tr td.table-module-td-select.ng-star-inserted .table-truncated-cell {
        text-align: center;
        max-width: 60px !important;
        min-width: 60px !important;
        width: 60px !important; }
      .table-default tbody tr th.table-module-th-select.ng-star-inserted mat-icon,
      .table-default tbody tr td.table-module-td-select.ng-star-inserted mat-icon, .table-default thead tr th.table-module-th-select.ng-star-inserted mat-icon,
      .table-default thead tr td.table-module-td-select.ng-star-inserted mat-icon {
        color: #92c731; }
    .table-default tbody tr td.table-module-td-email.ng-star-inserted div, .table-default thead tr td.table-module-td-email.ng-star-inserted div {
      min-width: 150px !important;
      word-break: break-all; }
    .table-default tbody tr th.table-module-th-asset,
    .table-default tbody tr td.table-module-td-asset,
    .table-default tbody tr th.table-module-th-action.ng-star-inserted,
    .table-default tbody tr td.table-module-td-action.ng-star-inserted,
    .table-default tbody tr th.table-module-th-id.ng-star-inserted,
    .table-default tbody tr td.table-module-td-id.ng-star-inserted, .table-default thead tr th.table-module-th-asset,
    .table-default thead tr td.table-module-td-asset,
    .table-default thead tr th.table-module-th-action.ng-star-inserted,
    .table-default thead tr td.table-module-td-action.ng-star-inserted,
    .table-default thead tr th.table-module-th-id.ng-star-inserted,
    .table-default thead tr td.table-module-td-id.ng-star-inserted {
      width: 90px !important; }
      .table-default tbody tr th.table-module-th-asset .table-fit-content-cell,
      .table-default tbody tr th.table-module-th-asset .table-truncated-cell,
      .table-default tbody tr td.table-module-td-asset .table-fit-content-cell,
      .table-default tbody tr td.table-module-td-asset .table-truncated-cell,
      .table-default tbody tr th.table-module-th-action.ng-star-inserted .table-fit-content-cell,
      .table-default tbody tr th.table-module-th-action.ng-star-inserted .table-truncated-cell,
      .table-default tbody tr td.table-module-td-action.ng-star-inserted .table-fit-content-cell,
      .table-default tbody tr td.table-module-td-action.ng-star-inserted .table-truncated-cell,
      .table-default tbody tr th.table-module-th-id.ng-star-inserted .table-fit-content-cell,
      .table-default tbody tr th.table-module-th-id.ng-star-inserted .table-truncated-cell,
      .table-default tbody tr td.table-module-td-id.ng-star-inserted .table-fit-content-cell,
      .table-default tbody tr td.table-module-td-id.ng-star-inserted .table-truncated-cell, .table-default thead tr th.table-module-th-asset .table-fit-content-cell,
      .table-default thead tr th.table-module-th-asset .table-truncated-cell,
      .table-default thead tr td.table-module-td-asset .table-fit-content-cell,
      .table-default thead tr td.table-module-td-asset .table-truncated-cell,
      .table-default thead tr th.table-module-th-action.ng-star-inserted .table-fit-content-cell,
      .table-default thead tr th.table-module-th-action.ng-star-inserted .table-truncated-cell,
      .table-default thead tr td.table-module-td-action.ng-star-inserted .table-fit-content-cell,
      .table-default thead tr td.table-module-td-action.ng-star-inserted .table-truncated-cell,
      .table-default thead tr th.table-module-th-id.ng-star-inserted .table-fit-content-cell,
      .table-default thead tr th.table-module-th-id.ng-star-inserted .table-truncated-cell,
      .table-default thead tr td.table-module-td-id.ng-star-inserted .table-fit-content-cell,
      .table-default thead tr td.table-module-td-id.ng-star-inserted .table-truncated-cell {
        text-align: center;
        max-width: 90px !important;
        min-width: 90px !important;
        width: 90px !important; }
      .table-default tbody tr th.table-module-th-asset .relative-inline-block,
      .table-default tbody tr td.table-module-td-asset .relative-inline-block,
      .table-default tbody tr th.table-module-th-action.ng-star-inserted .relative-inline-block,
      .table-default tbody tr td.table-module-td-action.ng-star-inserted .relative-inline-block,
      .table-default tbody tr th.table-module-th-id.ng-star-inserted .relative-inline-block,
      .table-default tbody tr td.table-module-td-id.ng-star-inserted .relative-inline-block, .table-default thead tr th.table-module-th-asset .relative-inline-block,
      .table-default thead tr td.table-module-td-asset .relative-inline-block,
      .table-default thead tr th.table-module-th-action.ng-star-inserted .relative-inline-block,
      .table-default thead tr td.table-module-td-action.ng-star-inserted .relative-inline-block,
      .table-default thead tr th.table-module-th-id.ng-star-inserted .relative-inline-block,
      .table-default thead tr td.table-module-td-id.ng-star-inserted .relative-inline-block {
        max-width: calc(100% - 22px); }
    .table-default tbody tr th.table-module-th-content,
    .table-default tbody tr td.table-module-td-content, .table-default thead tr th.table-module-th-content,
    .table-default thead tr td.table-module-td-content {
      min-width: 350px !important; }
      .table-default tbody tr th.table-module-th-content .table-fit-content-cell,
      .table-default tbody tr th.table-module-th-content .table-truncated-cell,
      .table-default tbody tr td.table-module-td-content .table-fit-content-cell,
      .table-default tbody tr td.table-module-td-content .table-truncated-cell, .table-default thead tr th.table-module-th-content .table-fit-content-cell,
      .table-default thead tr th.table-module-th-content .table-truncated-cell,
      .table-default thead tr td.table-module-td-content .table-fit-content-cell,
      .table-default thead tr td.table-module-td-content .table-truncated-cell {
        min-width: 350px !important; }
    .table-default tbody tr th.table-module-th-name,
    .table-default tbody tr td.table-module-td-name, .table-default thead tr th.table-module-th-name,
    .table-default thead tr td.table-module-td-name {
      max-width: 200px !important; }
      .table-default tbody tr th.table-module-th-name .table-fit-content-cell,
      .table-default tbody tr th.table-module-th-name .table-truncated-cell,
      .table-default tbody tr td.table-module-td-name .table-fit-content-cell,
      .table-default tbody tr td.table-module-td-name .table-truncated-cell, .table-default thead tr th.table-module-th-name .table-fit-content-cell,
      .table-default thead tr th.table-module-th-name .table-truncated-cell,
      .table-default thead tr td.table-module-td-name .table-fit-content-cell,
      .table-default thead tr td.table-module-td-name .table-truncated-cell {
        max-width: 200px !important;
        width: 200px; }
    .table-default tbody tr th.table-module-th-check,
    .table-default tbody tr th.table-module-th-delete,
    .table-default tbody tr td.table-module-td-asset,
    .table-default tbody tr td.table-module-td-assets,
    .table-default tbody tr td.table-module-td-action,
    .table-default tbody tr td.table-module-td-content,
    .table-default tbody tr td.table-module-td-check,
    .table-default tbody tr td.table-module-td-createdAt,
    .table-default tbody tr td.table-module-td-deletedAt,
    .table-default tbody tr td.table-module-td-updatedAt,
    .table-default tbody tr td.table-module-td-type,
    .table-default tbody tr td.table-module-td-select,
    .table-default tbody tr td.table-module-td-emailVerified,
    .table-default tbody tr td.table-module-td-mobilePlatformVersion,
    .table-default tbody tr td.table-module-td-edit, .table-default thead tr th.table-module-th-check,
    .table-default thead tr th.table-module-th-delete,
    .table-default thead tr td.table-module-td-asset,
    .table-default thead tr td.table-module-td-assets,
    .table-default thead tr td.table-module-td-action,
    .table-default thead tr td.table-module-td-content,
    .table-default thead tr td.table-module-td-check,
    .table-default thead tr td.table-module-td-createdAt,
    .table-default thead tr td.table-module-td-deletedAt,
    .table-default thead tr td.table-module-td-updatedAt,
    .table-default thead tr td.table-module-td-type,
    .table-default thead tr td.table-module-td-select,
    .table-default thead tr td.table-module-td-emailVerified,
    .table-default thead tr td.table-module-td-mobilePlatformVersion,
    .table-default thead tr td.table-module-td-edit {
      text-align: center; }
      .table-default tbody tr th.table-module-th-check .ng-star-inserted,
      .table-default tbody tr th.table-module-th-delete .ng-star-inserted,
      .table-default tbody tr td.table-module-td-asset .ng-star-inserted,
      .table-default tbody tr td.table-module-td-assets .ng-star-inserted,
      .table-default tbody tr td.table-module-td-action .ng-star-inserted,
      .table-default tbody tr td.table-module-td-content .ng-star-inserted,
      .table-default tbody tr td.table-module-td-check .ng-star-inserted,
      .table-default tbody tr td.table-module-td-createdAt .ng-star-inserted,
      .table-default tbody tr td.table-module-td-deletedAt .ng-star-inserted,
      .table-default tbody tr td.table-module-td-updatedAt .ng-star-inserted,
      .table-default tbody tr td.table-module-td-type .ng-star-inserted,
      .table-default tbody tr td.table-module-td-select .ng-star-inserted,
      .table-default tbody tr td.table-module-td-emailVerified .ng-star-inserted,
      .table-default tbody tr td.table-module-td-mobilePlatformVersion .ng-star-inserted,
      .table-default tbody tr td.table-module-td-edit .ng-star-inserted, .table-default thead tr th.table-module-th-check .ng-star-inserted,
      .table-default thead tr th.table-module-th-delete .ng-star-inserted,
      .table-default thead tr td.table-module-td-asset .ng-star-inserted,
      .table-default thead tr td.table-module-td-assets .ng-star-inserted,
      .table-default thead tr td.table-module-td-action .ng-star-inserted,
      .table-default thead tr td.table-module-td-content .ng-star-inserted,
      .table-default thead tr td.table-module-td-check .ng-star-inserted,
      .table-default thead tr td.table-module-td-createdAt .ng-star-inserted,
      .table-default thead tr td.table-module-td-deletedAt .ng-star-inserted,
      .table-default thead tr td.table-module-td-updatedAt .ng-star-inserted,
      .table-default thead tr td.table-module-td-type .ng-star-inserted,
      .table-default thead tr td.table-module-td-select .ng-star-inserted,
      .table-default thead tr td.table-module-td-emailVerified .ng-star-inserted,
      .table-default thead tr td.table-module-td-mobilePlatformVersion .ng-star-inserted,
      .table-default thead tr td.table-module-td-edit .ng-star-inserted {
        text-align: center; }
        .table-default tbody tr th.table-module-th-check .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr th.table-module-th-delete .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr td.table-module-td-asset .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr td.table-module-td-assets .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr td.table-module-td-action .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr td.table-module-td-content .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr td.table-module-td-check .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr td.table-module-td-createdAt .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr td.table-module-td-deletedAt .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr td.table-module-td-updatedAt .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr td.table-module-td-type .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr td.table-module-td-select .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr td.table-module-td-emailVerified .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr td.table-module-td-mobilePlatformVersion .ng-star-inserted .table-fit-content-cell,
        .table-default tbody tr td.table-module-td-edit .ng-star-inserted .table-fit-content-cell, .table-default thead tr th.table-module-th-check .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr th.table-module-th-delete .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr td.table-module-td-asset .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr td.table-module-td-assets .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr td.table-module-td-action .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr td.table-module-td-content .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr td.table-module-td-check .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr td.table-module-td-createdAt .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr td.table-module-td-deletedAt .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr td.table-module-td-updatedAt .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr td.table-module-td-type .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr td.table-module-td-select .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr td.table-module-td-emailVerified .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr td.table-module-td-mobilePlatformVersion .ng-star-inserted .table-fit-content-cell,
        .table-default thead tr td.table-module-td-edit .ng-star-inserted .table-fit-content-cell {
          text-align: center; }
    .table-default tbody tr td.table-module-td-id .ng-star-inserted,
    .table-default tbody tr td.table-module-td-createdBeamsCount .ng-star-inserted,
    .table-default tbody tr td.table-module-td-savedBeamCount .ng-star-inserted, .table-default thead tr td.table-module-td-id .ng-star-inserted,
    .table-default thead tr td.table-module-td-createdBeamsCount .ng-star-inserted,
    .table-default thead tr td.table-module-td-savedBeamCount .ng-star-inserted {
      text-align: right; }
    .table-default tbody tr td:last-child, .table-default tbody tr th:last-child, .table-default thead tr td:last-child, .table-default thead tr th:last-child {
      border-right: none; }

mat-tab-group, mat-tab-group .mat-tab-body-wrapper, mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content {
  height: 100%; }
  mat-tab-group .mat-ink-bar, mat-tab-group .mat-tab-body-wrapper .mat-ink-bar, mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content .mat-ink-bar {
    display: none !important;
    visibility: hidden !important; }
  mat-tab-group .mat-tab-label, mat-tab-group .mat-tab-body-wrapper .mat-tab-label, mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content .mat-tab-label {
    opacity: 1 !important; }
  mat-tab-group .table-label-title, mat-tab-group .mat-tab-body-wrapper .table-label-title, mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content .table-label-title {
    color: #000 !important;
    font-weight: 400; }
  mat-tab-group .table-label-title.nav-active, mat-tab-group .mat-tab-body-wrapper .table-label-title.nav-active, mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content .table-label-title.nav-active {
    color: #f45836; }

.data-edit-form .form-edit-new-content {
  width: calc(50% - 6px); }

.data-edit-form .mat-form-field, .data-edit-form .data-edit-form-item {
  width: 100%; }

.data-edit-form .data-edit-form-item {
  font-weight: 400;
  margin: 16px 0;
  height: 30px; }

.data-edit-form .user-profile-save {
  display: block;
  float: right;
  margin: 12px;
  position: relative; }

.data-edit-form .mat-card-header, .data-edit-form .mat-card-header {
  margin: 0; }

.page-content {
  background-color: #fff;
  position: relative;
  margin: 0;
  padding: 40px 10% 0;
  min-height: available;
  width: 100%;
  z-index: 2; }

.page-content .content-container {
  min-height: calc(100vh - 64px); }

.page-content.max-height-one-subnav .content-container {
  min-height: calc(100vh - 140px); }

.content-container, .content-container-bordered {
  display: block;
  position: relative;
  margin: 0 auto;
  padding: 0 0 100px 0; }

.content-container-bordered {
  border: 1px solid #D8D8D8;
  padding: 20px 20px 20px 20px;
  margin-bottom: 80px; }

.beam-create-container .btn-action-grey {
  width: 200px;
  margin-left: 0 !important; }

.table-container-scroll {
  display: inline-block;
  position: relative;
  min-height: 100px;
  max-height: calc(100vh - 250px);
  width: 100%;
  overflow-y: auto;
  vertical-align: top; }

.content-height-50 {
  min-height: 91px;
  max-height: calc(50vh - 125px); }

.content-height-20rows {
  min-height: 91px;
  max-height: 649px; }

.table-default.table-hover tbody tr:hover {
  background-color: #f8f9fa;
  cursor: pointer; }

/***************************************
    LOGIN/CONFIRM/RESET PAGES STYLES
****************************************/
.auth {
  height: 100vh;
  background: url(52e0c3e1b30a6e723b5a1d8a967a3909.png) no-repeat center top;
  background-size: cover; }
  .auth .btn-light {
    border: none;
    outline: none;
    border-radius: 2px;
    display: block;
    font-size: 16px;
    margin: 4px auto 5px;
    min-width: 60px;
    color: rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
    line-spacing: 1px;
    padding: 4px;
    text-align: center;
    background-color: transparent;
    cursor: pointer;
    width: 120px; }
  .auth .btn-light:disabled, .auth .btn-light[disabled], .auth .btn-light.disabled {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26); }
  .auth .signin-form-bg {
    background: transparent;
    background-size: cover;
    border-radius: 10px;
    padding: 0;
    position: absolute;
    left: calc(50vw - 350px);
    top: calc(50vh - 295px);
    height: 590px;
    width: 700px;
    min-height: 260px;
    min-width: 200px;
    text-align: center;
    vertical-align: middle; }
  .auth .signin-form {
    background-color: transparent;
    padding: 20px 0 0 0;
    position: relative;
    margin: 275px auto 0;
    height: 340px;
    width: 630px;
    min-height: 280px;
    min-width: 200px;
    text-align: center;
    vertical-align: middle; }
    .auth .signin-form .btn-light.mat-raised-button {
      background-color: #7ed321; }
    .auth .signin-form .btn-light .mat-button-wrapper {
      font-weight: 400;
      color: #fff; }
  .auth .inputs {
    height: 100%;
    position: relative; }
    .auth .inputs mat-input-container {
      width: 72%; }
    .auth .inputs input {
      display: block;
      font-size: 14px;
      background-color: transparent;
      border: none;
      line-height: 42px;
      outline: none;
      position: relative;
      text-align: left;
      width: 100%; }
    .auth .inputs .auth-input {
      background-color: #fff;
      border: 1px solid grey;
      border-radius: 3px;
      display: block;
      padding: 0 16px;
      margin: 0 auto 10px;
      height: 42px;
      width: 206px; }
    .auth .inputs mat-hint {
      background-color: #F40B0B;
      color: #fff;
      padding: 6px 12px;
      position: absolute;
      left: 440px;
      text-align: left;
      bottom: calc(50% - 16px);
      width: fit-content; }
  .auth .mat-form-field .mat-input-wrapper {
    margin: 0 auto 12px !important; }
  .auth .auth-links-container {
    display: block;
    text-align: center;
    min-height: 30px;
    width: 100%; }
  .auth .link {
    color: lightblue;
    cursor: pointer;
    display: block;
    font-size: 14px;
    line-height: 24px;
    margin: 5px auto 0;
    padding-bottom: 0;
    text-align: center;
    width: 100%;
    vertical-align: middle; }
  .auth .link:hover, .auth .actions .link.disabled {
    background-color: transparent !important; }
  .auth .actions button.btn-light {
    background-color: #00BBBC !important;
    color: #fff;
    margin: 0 auto;
    height: 42px;
    width: 206px; }
  .auth .reset-details-header {
    margin: 0 20px 8px;
    color: #fff;
    line-height: 20px; }
  .auth .title-container {
    background-color: #1F4F5A;
    margin: 0 0 40px;
    height: 112px;
    width: 100%; }
  .auth .title {
    color: #000;
    font-size: 24px;
    font-weight: 400;
    margin: 0 auto 10px;
    text-transform: capitalize; }
  .auth .error {
    color: red; }
  .auth .company-logo {
    -webkit-transform: translateZ(0) rotate(0.01deg);
    -ms-transform: translateZ(0) rotate(0.01deg);
    transform: translateZ(0) rotate(0.01deg);
    display: block;
    height: 192px;
    width: 626px;
    top: -240px;
    left: calc(50% - 313px);
    overflow: hidden;
    position: absolute; }
  .auth .data-table-checkbox input {
    display: none; }
  .auth .input-secondary-actions {
    margin: 0 auto 10px;
    padding: 0 5px;
    text-align: left;
    width: 206px; }
    .auth .input-secondary-actions .mat-checkbox-frame {
      border: 1px solid #fff; }
    .auth .input-secondary-actions .mat-checkbox-inner-container {
      height: 16px;
      width: 16px; }
  .auth .label-show-password {
    color: #fff;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    margin-left: 5px; }

/***************************************
       PAYMENT PAGE GLOBAL STYLES
****************************************/
.payment-form-page .mat-tab-label, .payment-form-page .mat-tab-label {
  min-width: 33% !important; }

.payment-form-page .mat-tab-header-pagination, .payment-form-page .mat-tab-header-pagination {
  display: none !important; }

.payment-form-page .input-card-details .mat-input-wrapper, .payment-form-page .input-card-details .mat-input-wrapper {
  margin-bottom: 0 !important;
  margin-top: 6px !important; }

/***************************************
          SEARCH BLOCK STYLES
****************************************/
.search-block-container {
  display: flex;
  float: left;
  margin: 0;
  padding: 0;
  position: relative;
  height: 48px;
  max-height: 48px; }
  .search-block-container .search-input-container {
    display: inline-block;
    margin: 0 12px;
    position: relative;
    height: 48px;
    max-height: 48px;
    width: 200px; }
    .search-block-container .search-input-container input, .search-block-container .search-input-container .search-input {
      border: none;
      font-size: 16px;
      outline: none;
      margin: 0;
      padding-left: 28px;
      transition: width .3s ease;
      line-height: 48px;
      height: 48px;
      width: 200px; }
    .search-block-container .search-input-container .mat-input-underline {
      background-color: #f45836; }
    .search-block-container .search-input-container .mat-input-underline .mat-input-ripple {
      background-color: #f45836; }
    .search-block-container .search-input-container .mat-form-field-label-wrapper .mat-form-field:not(.mat-focused) .mat-form-field-label {
      left: 24px !important; }
  .search-block-container .search-input-icon {
    color: #00BBBC;
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 2; }
  .search-block-container .search-mat-select {
    margin: 10px auto 0;
    vertical-align: middle; }
  .search-block-container .search-input-collapsed {
    transition: width .3s ease;
    color: transparent !important;
    min-width: 0 !important;
    width: 0 !important;
    overflow: hidden; }
  .search-block-container .search-block-collapsed {
    max-width: 60px;
    min-width: 60px;
    width: 60px; }
  .search-block-container .search-input-collapsed + .mat-input-placeholder-wrapper {
    display: none; }

/***************************************
           MD DIALOG STYLES
****************************************/
.mat-dialog-container .cdk-focus-trap-content {
  color: #000; }

.news-feed-container .news-feed-row-module:nth-child(odd) .news-feed-row-container {
  background-color: #E0E0E0; }

.td-dialog-actions {
  margin-right: 40px; }
  .td-dialog-actions td-dialog-actions {
    text-align: center;
    width: 100%; }
    .td-dialog-actions td-dialog-actions .btn-action-grey, .td-dialog-actions td-dialog-actions .btn-action-white {
      background-color: #00BBBC;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      font-size: 16px;
      line-height: 35px;
      padding: 0 20px;
      min-width: 80px;
      text-align: center;
      width: auto; }
    .td-dialog-actions td-dialog-actions .btn-action-white {
      background-color: #fff;
      border: 1px solid #00BBBC;
      color: #00BBBC; }
    .td-dialog-actions td-dialog-actions button:nth-child(1) {
      background-color: #fff;
      border: 1px solid #00BBBC;
      color: #00BBBC;
      border-radius: 3px;
      cursor: pointer;
      font-size: 16px;
      line-height: 35px;
      padding: 0 20px;
      min-width: 80px;
      text-align: center;
      width: auto;
      margin-bottom: 10px; }
      .td-dialog-actions td-dialog-actions button:nth-child(1) .mat-button-wrapper {
        color: #00BBBC; }
    .td-dialog-actions td-dialog-actions button:nth-child(2) {
      background-color: #00BBBC;
      border-radius: 3px;
      border: none;
      color: #fff;
      cursor: pointer;
      font-size: 16px;
      line-height: 35px;
      padding: 0 20px;
      min-width: 80px;
      text-align: center;
      width: auto;
      margin-bottom: 10px; }
      .td-dialog-actions td-dialog-actions button:nth-child(2) .mat-button-wrapper {
        color: #fff; }

/***************************************
           SWIPER STYLES
****************************************/
.swiper-button-next, .swiper-button-prev {
  background-image: none !important;
  text-align: center;
  width: 96px;
  height: 96px; }
  .swiper-button-next .material-icons, .swiper-button-prev .material-icons {
    color: #fff;
    font-size: 96px;
    margin-top: -70px !important;
    margin-right: 54px;
    vertical-align: middle; }

.swiper-button-prev {
  margin-left: -24px; }

.swiper-button-next {
  margin-right: -24px; }

.swiper-pagination-bullet {
  color: #fff; }

.news-feed-container .news-feed-row-module:nth-child(odd) .news-feed-row-container {
  background-color: #E0E0E0; }

.campaign-page .campaign-name-input {
  margin-left: 6px;
  margin-right: 10px; }

.campaign-page .swiper-button-next, .campaign-page .swiper-button-prev {
  width: 96px;
  height: 96px; }
  .campaign-page .swiper-button-next .material-icons, .campaign-page .swiper-button-prev .material-icons {
    color: #585b5d;
    font-size: 46px;
    margin-top: -100px;
    margin-right: 24px;
    vertical-align: middle; }

.campaign-page .swiper-button-prev {
  margin-left: -24px; }

.campaign-page .swiper-button-next {
  margin-right: -24px; }

.campaign-page .swiper-pagination-bullet {
  color: #000; }

.campaign-page .news-feed-container .news-feed-row-module:nth-child(odd) .news-feed-row-container {
  background-color: #E0E0E0; }

.campaign-page .data-table-container .table-pagination-bar {
  width: 100%; }

.standalone-beam-cover-img {
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  left: calc(50% + 2px) !important;
  top: calc(51% - 6px) !important;
  transform: translate(-50%, -50%);
  width: 194px !important;
  max-height: 194px;
  max-width: 194px;
  z-index: 98; }

/***************************************
        SURVEY DATA ITEMS STYLES
****************************************/
.container-half {
  display: inline-block;
  margin: 0;
  padding: 0 12px 12px 0;
  position: relative;
  text-align: left;
  width: calc(50% - 2px);
  vertical-align: top; }
  .container-half .survey-row {
    margin: 0 0 12px;
    padding: 0;
    display: block;
    height: auto;
    min-height: 48px;
    width: 100%; }
    .container-half .survey-row .beam-asset-img {
      margin: 0 !important;
      float: left;
      height: 48px;
      width: auto; }
    .container-half .survey-row .survey-data-title {
      float: right;
      line-height: 48px;
      margin: 0;
      padding: 0 12px;
      text-align: left;
      width: calc(100% - 96px);
      vertical-align: middle; }
  .container-half .appeals-container {
    float: left; }
  .container-half .interests-container {
    float: right; }

.main-container .page-content .mat-tab-header-pagination, .main-container .page-content .mat-tab-header-pagination {
  display: block !important;
  padding: 18px 12px; }

.main-container .page-content .mat-tab-label, .main-container .page-content .mat-tab-label {
  min-width: 156px !important; }

.cdk-overlay-pane {
  max-width: 720px; }
  .cdk-overlay-pane .mat-menu-panel {
    max-width: 720px; }

.btn-action-green, .btn-action-red, .btn-action-blue {
  /* Style for "Rectangle" */
  color: #fff !important;
  display: inline-block;
  margin: 3px 20px !important;
  position: relative;
  width: auto;
  height: 42px;
  border-radius: 2px; }

.btn-action-green {
  border: 1px solid #5eb000 !important;
  background-color: #7ed321 !important; }

.btn-action-red {
  border: 1px solid #d85836 !important;
  background-color: #f45836 !important;
  margin-left: 0 !important; }

.btn-action-blue {
  border: 1px solid #00BBBC !important;
  background-color: #00BBBC !important; }

.btn-action-green-outline {
  /* Style for "Rectangle" */
  box-shadow: none !important;
  color: #5eb000 !important;
  display: inline-block;
  margin: 20px 0 !important;
  position: relative;
  width: auto;
  height: 42px;
  border-radius: 2px;
  border: 1px solid #5eb000 !important;
  background-color: #fff; }

.btn-action-lightgrey, .btn-action-grey {
  /* Style for "Rectangle" */
  display: inline-block;
  margin: 3px 20px !important;
  position: relative;
  text-align: center;
  min-width: 42px !important;
  width: auto;
  height: 35px;
  border-radius: 3px;
  border: none !important;
  background-color: #f8f9fa; }

.btn-action-grey, .btn-action-white {
  background-color: #00BBBC;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  line-height: 35px;
  padding: 0 20px;
  min-width: 80px;
  text-align: center;
  width: auto; }
  .btn-action-grey-outline, .btn-action-white-outline {
    color: #00BBBC;
    border: 1px solid #00BBBC !important;
    background-color: #fff; }

.btn-action-white {
  background-color: #fff;
  border: 1px solid #00BBBC;
  color: #00BBBC; }

.btn-narrow, .btn-action-lightgrey.btn-narrow {
  background-color: #f8f9fa;
  margin: 3px 10px !important;
  padding: 0 !important;
  min-width: 42px !important;
  width: 42px !important; }

.list-intro-decimal li {
  list-style-type: decimal; }

.list-intro-dotted li {
  list-style-type: initial; }

::ng-deep .mat-tab-header-pagination {
  display: none !important; }

.main-container .page-content .mat-tab-header-pagination,
.main-container .page-content .mat-tab-header-pagination {
  display: none !important; }

.margin-0-auto {
  margin: 0 auto !important; }

.margin-10-auto {
  margin: 10px auto !important; }

.block-title {
  margin: 0;
  padding: 20px 20px 0; }

.margin-5 {
  margin: 5px; }

.padding-20-20-60 {
  padding: 20px 20px 60px !important; }

.padding-40-40-60 {
  padding: 40px 40px 60px !important; }

.margin-0 {
  margin: 0 !important; }

.align-center {
  align-items: center !important; }

/***************************************
              SUB NAV STYLES
****************************************/
.sub-nav-container {
  position: relative;
  margin: 0;
  padding: 0 0 0 202px;
  width: 100%;
  z-index: 2; }
  .sub-nav-container mat-tab-group .mat-tab-header, .sub-nav-container mat-tab-group .mat-tab-nav-bar {
    border: none !important; }
  .sub-nav-container .table-label-title {
    font-size: 13px;
    min-width: 60px; }

.sub-nav-a {
  background-color: transparent; }
  .sub-nav-a .mat-tab-label {
    padding: 0 !important; }
  .sub-nav-a .table-label-title {
    padding: 0 20px; }
  .sub-nav-a .nav-active {
    background-color: #F7F7F7;
    color: #00BBBC !important;
    font-weight: 500; }

.sub-nav-b {
  background-color: #fff; }
  .sub-nav-b .table-label-title {
    min-width: auto;
    font-size: 13px;
    height: 30px !important;
    line-height: 30px; }
  .sub-nav-b .nav-active {
    border-bottom: 2px solid #00bbbc;
    color: #000 !important; }
  .sub-nav-b .mat-tab-label {
    padding: 0 20px; }

.max-height-one-subnav {
  max-height: calc(100vh - 92px);
  min-height: calc(100vh - 92px);
  overflow-y: auto; }

.max-height-two-subnav {
  max-height: calc(100vh - 160px);
  min-height: calc(100vh - 160px);
  overflow-y: auto; }

.blocking-overlay-100 {
  text-align: center;
  padding: 40% 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: .2; }

.border-23-dashed {
  border: 2px dashed lightgrey;
  border-radius: 23px;
  min-width: 96px !important; }

.password-recovery-page .auth-credentials-container {
  margin-top: 30px; }

/***************************************
           NGX CROPPER STYLES
****************************************/
ngx-image-editor .ngx-image-editor-component .file-name {
  opacity: 0; }

ngx-image-editor .ngx-image-editor-component .dialog-crop-container {
  margin: 0 auto; }
  ngx-image-editor .ngx-image-editor-component .dialog-crop-container .img-container .cropper-face.cropper-move {
    border: 1px solid #fff;
    border-radius: 50%;
    background: transparent;
    opacity: 1; }
  ngx-image-editor .ngx-image-editor-component .dialog-crop-container .img-container .cropper-face.cropper-move:before {
    border: 1px solid #000;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    height: calc(100% - 2px);
    width: calc(100% - 2px); }
  ngx-image-editor .ngx-image-editor-component .dialog-crop-container .img-container .cropper-point {
    background: #000;
    border: .5px solid #fff;
    opacity: .8; }
  ngx-image-editor .ngx-image-editor-component .dialog-crop-container .cropper-view-box {
    outline: none; }
  ngx-image-editor .ngx-image-editor-component .dialog-crop-container .cropper-dashed {
    border: none; }
  ngx-image-editor .ngx-image-editor-component .dialog-crop-container .cropper-line {
    background-color: transparent; }
  ngx-image-editor .ngx-image-editor-component .dialog-crop-container .cropped-image.ng-star-inserted img {
    width: 350px !important;
    height: 350px !important; }

ngx-image-editor .ngx-image-editor-component .cropped-image.ng-star-inserted {
  border: 2px solid #999999;
  border-radius: 50%;
  display: inline-flex;
  overflow: hidden;
  width: 350px;
  height: 350px; }

ngx-image-editor .ngx-image-editor-component .dialog-button-actions .mat-button-toggle-group {
  display: none; }

ngx-image-editor .ngx-image-editor-component .dialog-button-actions .image-zoom {
  color: #00BBBC !important;
  flex-flow: row-reverse;
  position: absolute;
  left: calc(50% - 114px) !important; }

ngx-image-editor .ngx-image-editor-component .dialog-button-actions .cropped-image-buttons .mat-raised-button {
  box-shadow: none;
  color: #00BBBC !important;
  display: inline-block;
  font-size: 16px;
  margin: 3px 10px !important;
  padding: 0 10px;
  position: relative;
  text-transform: capitalize;
  width: auto;
  height: 42px;
  border-radius: 2px;
  border: none !important;
  background-color: transparent !important; }
  ngx-image-editor .ngx-image-editor-component .dialog-button-actions .cropped-image-buttons .mat-raised-button .mat-button-wrapper span, ngx-image-editor .ngx-image-editor-component .dialog-button-actions .cropped-image-buttons .mat-raised-button .mat-button-wrapper mat-icon {
    display: none; }

ngx-image-editor .ngx-image-editor-component .dialog-button-actions .cropped-image-buttons .mat-raised-button:nth-of-type(1):after {
  font-size: 16px;
  font-weight: 400;
  content: 'Save to Library'; }

ngx-image-editor .ngx-image-editor-component .dialog-button-actions .cropped-image-buttons .mat-raised-button:nth-of-type(2):after {
  font-size: 16px;
  font-weight: 400;
  content: 'Undo'; }

ngx-image-editor .ngx-image-editor-component .image-dimensions {
  display: none; }

ngx-image-editor .ngx-image-editor-component .canvas-config {
  height: 1px !important;
  overflow: hidden;
  opacity: 0; }

ngx-image-editor .ngx-image-editor-component .photo-editor-header.mat-dialog-title button.mat-icon-button {
  box-shadow: none;
  color: #00BBBC !important;
  display: inline-block;
  margin: 3px 10px !important;
  padding: 0 10px;
  position: relative;
  width: auto;
  height: 42px;
  border-radius: 2px;
  border: none !important;
  background-color: transparent !important; }
  ngx-image-editor .ngx-image-editor-component .photo-editor-header.mat-dialog-title button.mat-icon-button mat-icon {
    display: none; }

ngx-image-editor .ngx-image-editor-component .photo-editor-header.mat-dialog-title button:nth-of-type(1).mat-icon-button:before {
  font-size: 16px;
  font-weight: 400;
  content: 'Preview'; }

ngx-image-editor .ngx-image-editor-component .photo-editor-header.mat-dialog-title button:nth-of-type(1) {
  position: absolute;
  left: 0;
  top: 440px; }

ngx-image-editor .ngx-image-editor-component .photo-editor-header.mat-dialog-title button:nth-of-type(2).mat-icon-button:before {
  font-size: 16px;
  font-weight: 400;
  content: 'Center Image'; }

ngx-image-editor .ngx-image-editor-component .photo-editor-header.mat-dialog-title button:nth-of-type(2) {
  position: absolute;
  left: 100px;
  top: 440px; }

ngx-image-editor .ngx-image-editor-component .mat-icon-button.mat-accent {
  color: #00BBBC !important; }

ngx-image-editor .ngx-image-editor-component .photo-editor-header > .mat-icon {
  display: none; }

ngx-image-editor .ngx-cropper-custom-options .inline-block {
  color: #00BBBC !important; }

.cropper-color-picker {
  border: 2px solid #ebebeb;
  border-radius: 6px;
  margin-right: 10px;
  padding: 0;
  outline: none;
  cursor: pointer; }

.cropper-version-toggle {
  height: 48px;
  line-height: 48px;
  margin: 0 20px; }

.icon-item-purpose {
  font-size: 16px !important;
  left: -4px !important;
  top: -2px !important;
  line-height: 16px !important;
  height: 16px !important;
  width: 16px !important; }

.btn-datetime-now {
  border: 2px solid #000;
  color: #000;
  position: absolute;
  right: 35px;
  top: 12px;
  padding: 3px 5px;
  height: 26px;
  width: auto;
  font-weight: 600; }

.list-spaced ul li {
  margin-bottom: 10px; }

.members-module .members-section {
  width: 100% !important; }
  .members-module .members-section .bordered {
    min-height: 100px; }

/***************************************
           MEDIA QUERY STYLES
****************************************/
@media screen and (min-width: 1023px) {
  .auth.payment-form-page.direct-beam-page {
    background: #fff;
    background-size: cover !important;
    overflow-y: auto;
    padding-top: 160px !important; }
  .payment-form.direct-beam-form {
    padding-top: 18px !important; } }

@media screen and (max-width: 1023px) {
  .beam-title {
    color: transparent !important; }
  .beam-logo {
    opacity: 1 !important; }
  .main-container .side-bar-show-hide-button {
    left: -47px; }
  .main-container .page-content .mat-tab-label, .main-container .page-content .mat-tab-label {
    min-width: 48px !important; }
  .page-content {
    padding: 20px 0 60px; }
  .page-content > .data-table-container,
  content .page-content .content-container > .data-table-container {
    border: none; }
  .app-footer {
    padding: 0 20px; } }

@media screen and (max-height: 767px) {
  .auth .signin-form {
    height: 270px; } }

@media screen and (max-width: 767px) {
  .data-table-container .data-table-actions-container {
    margin-top: 0 !important; }
  .dynamic-content-divider {
    display: none !important; }
  .data-edit-form {
    margin: 0 !important;
    width: 100% !important; }
  .form-edit-new-content, .form-edit-new-content .mat-form-field, .form-edit-new-content .data-edit-form-item {
    margin: 0 12px;
    width: calc(100% - 24px) !important;
    max-width: calc(100% - 24px) !important; }
  .form-edit-new-content .data-edit-form-item {
    margin: 16px 12px !important; }
  .form-edit-new-content .mat-form-field:first-child {
    margin-top: 12px; }
  .mat-tab-header-pagination {
    display: flex !important; }
  .profile-action-button, .beam-image-card, .crowdrise-connected-banner {
    margin-left: 12px !important;
    margin-right: 12px !important; }
  .tag-add-button {
    right: 24px !important; }
  .user-table-actions {
    min-width: 36px !important;
    padding: 0 !important;
    width: 36px !important; }
  .table-action-button {
    overflow: hidden;
    padding-left: 6px !important;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    width: 62px !important; }
  .user-table-actions.table-action-button.back-button, .user-table-actions.table-action-button.save-button {
    width: 82px !important; }
  .desktop-content {
    display: none !important; }
  .mobile-content, .beam-image-card.mobile-content.mat-card, .beam-image-card.mobile-content.mat-card {
    display: inline-block !important;
    width: calc(100% - 24px); }
  .profile-action-button {
    min-width: 150px !important; }
  .profile-action-button.connect-crowdrise-button {
    min-width: 202px !important;
    overflow: visible; }
  .container-half {
    padding-left: 24px;
    width: 100% !important; }
  .payment-form-page.direct-beam-page {
    padding-top: 25vh !important; }
  .payment-form.direct-beam-form {
    padding-top: 28px !important; }
  .payment-form.direct-beam-form .asset-container {
    margin-top: 0 !important; }
  .auth .company-logo {
    left: 20px !important;
    height: auto !important;
    width: calc(100% - 40px) !important; } }

@media (max-width: 600px) and (orientation: portrait) {
  .mat-toolbar-row {
    height: 56px !important; }
  .search-block-container {
    max-width: 80px !important; }
  .mat-menu-item, .mat-menu-item {
    letter-spacing: -1px !important; }
  .direct-beam-header {
    padding: 0 !important;
    text-align: center !important;
    top: 0;
    height: 120px; }
    .direct-beam-header a {
      display: inline-block !important;
      position: relative;
      float: none !important;
      margin: 12px auto 24px;
      height: 48px !important;
      width: 47% !important;
      vertical-align: top; }
    .direct-beam-header a:first-child {
      width: 60% !important; }
    .direct-beam-header img {
      display: inline-block !important;
      position: relative;
      margin: 12px 2px !important;
      transform: scale(0.5); }
  .notification-textarea {
    margin: 0 12px !important;
    width: 280px !important; }
  users-table {
    margin: 0 !important; } }

@media screen and (min-width: 568px) {
  .payment-form-page .payment-form {
    max-width: 100vw !important; }
  .payment-form-page .input-donation-billing-info {
    float: left;
    max-width: calc(50% - 48px) !important; }
  .payment-form-page .full-width {
    max-width: calc(100% - 24px) !important; } }

@media screen and (max-width: 640px) {
  .payment-form-page .payment-form {
    max-width: 100vw !important; }
  .payment-form-page .input-donation-billing-info {
    float: left; }
  .payment-form-page .full-width {
    max-width: calc(100% - 24px) !important; } }

@media screen and (min-width: 768px) {
  .payment-form-page .payment-form {
    width: 70vw !important;
    max-width: 768px !important; } }

@media screen and (max-width: 368px) {
  .filter-button {
    display: none !important; }
  .mat-tab-label, .mat-tab-label {
    min-width: 45px !important;
    width: 45px;
    padding: 0 4px; }
  .mat-dialog-container {
    max-width: 100vw !important; } }

.stripped-image {
  border: 3px solid transparent;
  border-image-source: url(bbf534d20c368874bf8c22ac2fe61c96.png);
  border-image-repeat: round;
  border-image-slice: 6 fill;
  border-radius: 50%; }
